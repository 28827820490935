<template>
  <div class="attendance-dialog-component">
    <el-form
      :model="ruleForm"
      :rules="rules"
      label-position="top"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
    >
      <el-row>
        <el-form-item label="考勤名称" prop="attendanceName">
          <el-input
            placeholder="请输入考勤名称"
            v-model="ruleForm.attendanceName"
            maxlength="20"
          ></el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="考勤符号" prop="attendanceSign">
          <el-input
            placeholder="请输入考勤符号"
            v-model="ruleForm.attendanceSign"
            maxlength="2"
          ></el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="说明" prop="description">
          <el-input
            placeholder="请输入说明"
            v-model="ruleForm.description"
            maxlength="50"
            show-word-limit
          ></el-input>
        </el-form-item>
      </el-row>
    </el-form>
    <div class="footer-container">
      <el-button @click="$emit('close', false)">取消</el-button>
      <el-button type="primary" @click="submitForm('ruleForm')" v-preventReClick
        >确定</el-button
      >
    </div>
  </div>
</template>

<script>
import {
  addNewAttendance,
  updateAttendance,
} from "@/api/business/shifts/attendanceSetting.js";
export default {
  name: "attendanceDialogComponent",
  props: {
    dataset: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    dataset: {
      handler(value) {
        if (value.id) {
          this.ruleForm = value;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      ruleForm: {
        attendanceName: "",
        attendanceSign: "",
        description: "",
      },
      rules: {
        attendanceName: [
          { required: true, message: "请填写考勤名称", trigger: "blur" },
        ],
        attendanceSign: [
          { required: true, message: "请填写考勤符号", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.submitHandler();
        } else {
          return false;
        }
      });
    },
    submitHandler() {
      if (this.dataset.id) {
        updateAttendance(this.ruleForm).then((res) => {
          if (res === 1) {
            this.$message.success("修改考勤成功！");
            this.$emit("close", true);
          }
        });
      } else {
        addNewAttendance(this.ruleForm).then((res) => {
          if (res === 1) {
            this.$message.success("新增考勤成功！");
            this.$emit("close", true);
          }
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.attendance-dialog-component {
  .footer-container {
    margin: 20px 0;
    text-align: right;
  }
}
</style>