import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

/**
 * 考勤设置 - 列表查询
 * @param params
 */
export function getAttendanceList(params) {
  return request({
    url: envInfo.bgApp.schedulePath + '/attendance/page',
    method: 'get',
    params,
  });
}

/**
 * 考勤设置 - 新增
 * @param params
 */
export function addNewAttendance(params) {
  return request({
    url: envInfo.bgApp.schedulePath + '/attendance/create',
    method: 'post',
    data: params,
  });
}

/**
 * 考勤设置 - 修改
 * @param params
 */
export function updateAttendance(params) {
  return request({
    url: envInfo.bgApp.schedulePath + '/attendance/update',
    method: 'post',
    data: params,
  });
}

/**
 * 考勤设置 - 删除
 * @param params
 */
export function deleteAttendance(params) {
  return request({
    url: envInfo.bgApp.schedulePath + '/attendance/delete',
    method: 'delete',
    params,
  });
}
