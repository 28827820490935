<template>
  <div class="app-container attendance-setting-list">
    <div class="filter-line">
      <span>
        <el-button type="primary" @click="createAttendance">添加考勤</el-button>
        <el-button
          plain
          icon="el-icon-refresh-right"
          @click="freshHandler"
          v-preventReClick
          >刷新</el-button
        >
      </span>
      <div
        class="header-filter-button"
        @click="dataset.filterLine.show = !dataset.filterLine.show"
      >
        筛选
        <i class="el-icon-arrow-down" v-if="dataset.filterLine.show"></i>
        <i class="el-icon-arrow-right" v-else></i>
      </div>
    </div>
    <div class="table-container">
      <finalTable
        ref="finalTableRef"
        :datas="dataset"
        @tableEventHandler="tableEventHandler"
      />
    </div>
    <!-- 新增&编辑弹框 -->
    <el-dialog
      :visible.sync="dialogVisible"
      :before-close="dialogCloseHandler"
      width="728px"
      :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <template slot="title">
        <span>{{ dialogTitle }}</span>
      </template>
      <AttendanceDialog
        v-if="dialogVisible"
        @close="dialogCloseHandler"
        :dataset="modifyDatas"
      />
    </el-dialog>
  </div>
</template>

<script>
import finalTable from "@/components/FinalTable";
import AttendanceDialog from "./attendanceDialog.vue";
import {
  getAttendanceList,
  deleteAttendance,
} from "@/api/business/shifts/attendanceSetting.js";
import { deepClone } from "@/utils/utils.js";
export default {
  name: "attendanceSettingList",
  components: {
    finalTable,
    AttendanceDialog,
  },
  data() {
    return {
      loadingFlag: false,
      dialogVisible: false,
      dialogTitle: "新增考勤",
      modifyDatas: {},
      requestParams: {
        current: 1,
        rowCount: 10,
      },
      dataset: {
        // 是否需要分页
        paginationConfig: {
          need: true,
        },
        // 是否需要查询行
        filterLine: {
          show: true,
        },
        // 行勾选
        selection: {
          need: false,
        },
        header: [
          {
            prop: "attendanceName",
            label: "考勤名称",
            width: "",
          },
          {
            prop: "attendanceSign",
            label: "考勤符号",
            width: "",
          },
          {
            prop: "description",
            label: "说明",
            width: "",
          },
          {
            prop: "operation",
            label: this.$t("commons.actions"),
            width: "120",
          },
        ],
        tableData: [],
        // 搜索行配置
        searchLineConfig: {
          attendanceName: {
            type: "input",
            label: "考勤名称",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入考勤名称",
            prefixIcon: "el-icon-search",
          },
        },
        // 表格内容配置
        detailConfig: {
          operation: {
            type: "icon",
            iconList: [
              {
                actionType: "iconClick",
                eventName: "modify",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-view",
                tooltips: "编辑",
              },
              {
                actionType: "iconClick",
                eventName: "delete",
                fontSize: "14px",
                color: "#E47470",
                cursorPointer: "pointer",
                iconName: "el-icon-delete",
                tooltips: "删除",
              },
            ],
          },
        },
        advanceFilterConfig: {},
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
    };
  },
  watch: {
    loadingFlag: {
      handler(val) {
        this.$refs.finalTableRef.loadingToggle(val);
      },
    },
  },
  created() {
    this.getTableList();
  },
  methods: {
    getTableList() {
      this.loadingFlag = true;
      getAttendanceList(this.requestParams)
        .then((res) => {
          this.dataset.tableData = res.rows;
          this.dataset.pageVO.total = res.total;
        })
        .finally(() => {
          this.loadingFlag = false;
        });
    },
    createAttendance() {
      this.modifyDatas = {};
      this.dialogTitle = "新增考勤";
      this.dialogVisible = true;
    },
    freshHandler() {
      this.getTableList();
    },
    tableEventHandler(datas) {
      if (datas.type === "goSearch") {
        this.requestParams = { ...this.requestParams, ...datas.params };
        this.getTableList();
      } else if (datas.type === "paginationChange") {
        this.requestParams.current = datas.params.current.page;
        this.requestParams.rowCount = datas.params.current.limit;
        this.getTableList();
      } else if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "modify":
            this.attendanceModifyHandler(datas.row);
            break;
          case "delete":
            this.deleteAttendance(datas.row.id);
            break;
        }
      }
    },
    deleteAttendance(id) {
      this.$confirm(
        this.$t("message.deleteConfirm"),
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          deleteAttendance({
            id,
          }).then(() => {
            // 成功提示
            this.$message({
              message: this.$t("message.deleteSuccess"),
              type: "success",
            });
            this.getTableList();
          });
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
    attendanceModifyHandler(datas) {
      this.dialogTitle = "修改考勤";
      this.modifyDatas = deepClone(datas);
      this.dialogVisible = true;
    },
    dialogCloseHandler(freshFlag) {
      this.dialogVisible = false;
      if (freshFlag) this.getTableList();
    },
  },
};
</script>

<style lang="less" scoped>
.attendance-setting-list {
  .filter-line {
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      height: 36px;
    }
    .icon-loudoutu {
      font-size: 12px;
    }
    .header-filter-button {
      cursor: pointer;
      width: 84px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 3px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      border: 1px solid #e7e8eb;
    }
    .not-allowed-button {
      cursor: not-allowed;
      background-color: #fef0f0;
      border-color: #fde2e2;
      color: #f9a7a7;
    }
  }
}
</style>