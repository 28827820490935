var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "attendance-dialog-component" },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-position": "top",
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { attrs: { label: "考勤名称", prop: "attendanceName" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入考勤名称", maxlength: "20" },
                    model: {
                      value: _vm.ruleForm.attendanceName,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "attendanceName", $$v)
                      },
                      expression: "ruleForm.attendanceName",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { attrs: { label: "考勤符号", prop: "attendanceSign" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "请输入考勤符号", maxlength: "2" },
                    model: {
                      value: _vm.ruleForm.attendanceSign,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "attendanceSign", $$v)
                      },
                      expression: "ruleForm.attendanceSign",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { attrs: { label: "说明", prop: "description" } },
                [
                  _c("el-input", {
                    attrs: {
                      placeholder: "请输入说明",
                      maxlength: "50",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.ruleForm.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "description", $$v)
                      },
                      expression: "ruleForm.description",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "footer-container" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.$emit("close", false)
                },
              },
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              directives: [
                { name: "preventReClick", rawName: "v-preventReClick" },
              ],
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submitForm("ruleForm")
                },
              },
            },
            [_vm._v("确定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }